import { graphql } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

import { QuiverQuantitativePortifolio } from '_templates'

const QuiverQuantStudyCase = ({ data }) => {
  const content = data.contentfulQuiverQuantitativeUseCase

  return (
    <QuiverQuantitativePortifolio
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageBackgroundImage={content.pageBackgroundImage.file.url}
      proudCases={content.proudCases}
      logo={content.FirstSectionQuiverQuantitativeLogoAndName.file.url}
      logoDescription={content.FirstSectionQuiverQuantitativeLogoAndName.description}
      cellphoneDescription={content.firstSectionCellphones.description}
      aboutDescription={content.firstSectionHeroText.internal.content}
      cellphones={content.firstSectionCellphones.file.url}
      secondSectionTitleText={content.secondSectionTitleText}
      secondSectionText1={content.secondSectionText1.internal.content}
      secondSectionImgGroup={content.secondSectionImgGroup.file.url}
      secondSectionImgGroupDescription={content.secondSectionImgGroup.description}
      thirdSectionText={content.thirdSectionText.internal.content}
      thirdSectionBackgroundImg={content.thirdSectionBackgroundImg.fluid.srcWebp}
      thirdSectionFrontCellphone={content.thirdSectionFrontCellphone.file.url}
      thirdSectionFrontCellphoneDescription={content.thirdSectionFrontCellphone.description}
      thirdSectionBackgroundImgMobile={content.thirdSectionBackgroundImgMobile.fluid.srcWebp}
      thirdSectionTitleText={content.thirdSectionTitleText}
      fourthSectionCellphone1={content.fourthSectionCellphone1.file.url}
      fourthSectionCellphone1Description={content.fourthSectionCellphone1.description}
      fourthSectionCellphone2={content.fourthSectionCellphone2.file.url}
      fourthSectionCellphone2Description={content.fourthSectionCellphone2.description}
      fourthSectionText1={content.fourthSectionText1.internal.content}
      fourthSectionText2={content.fourthSectionText2.internal.content}
      fourthSectionTitleText1={content.fourthSectionTitleText1}
      fourthSectionTitleText2={content.fourthSectionTitleText2}
      sixthStripeText1={content.sixthStripeText1.internal.content}
      sixthStripeImg1={content.sixthStripeImg1.file.url}
      sixthStripeImg1Description={content.sixthStripeImg1.description}
      sixthStripeImg2={content.sixthStripeImg2.file.url}
      sixthStripeImg2Description={content.sixthStripeImg2.description}
      sixthStripe={content.sixthStripe.file.url}
      sixthStripeText2={content.sixthStripeText2.internal.content}
      testimonials={content.testimonials}
    />
  )
}

export const query = graphql`
  query contentfulQuiverQuantitativeUseCase($locale: String) {
    contentfulQuiverQuantitativeUseCase(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageBackgroundImage {
        description
        file {
          url
        }
      }
      FirstSectionQuiverQuantitativeLogoAndName {
        description
        file {
          url
        }
      }
      firstSectionHeroText {
        internal {
          content
        }
      }
      firstSectionCellphones {
        description
        file {
          url
        }
      }
      secondSectionTitleText
      thirdSectionTitleText
      fourthSectionTitleText1
      fourthSectionTitleText2
      secondSectionText1 {
        internal {
          content
        }
      }
      secondSectionImgGroup {
        description
        file {
          url
        }
      }
      thirdSectionText {
        internal {
          content
        }
      }
      thirdSectionTitleImg {
        description
        file {
          url
        }
      }
      thirdSectionBackgroundImg {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      thirdSectionBackgroundImgMobile {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      thirdSectionFrontCellphone {
        description
        file {
          url
        }
      }
      fourthSectionCellphone1 {
        description
        file {
          url
        }
      }
      fourthSectionCellphone2 {
        description
        file {
          url
        }
      }
      fourthSectionTitle1 {
        description
        file {
          url
        }
      }
      fourthSectionTitle2 {
        description
        file {
          url
        }
      }
      fourthSectionText1 {
        internal {
          content
        }
      }
      fourthSectionText2 {
        internal {
          content
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      sixthStripeText1 {
        internal {
          content
        }
      }
      sixthStripeImg1 {
        description
        file {
          url
        }
      }
      sixthStripeImg2 {
        description
        file {
          url
        }
      }
      sixthStripe {
        description
        file {
          url
        }
      }
      sixthStripeText2 {
        internal {
          content
        }
      }
      testimonials {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
    }
  }
`

QuiverQuantStudyCase.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default QuiverQuantStudyCase
